import {Component, Vue} from 'vue-property-decorator';
import {Evaluation} from "../../shared/model/Evaluation";
import {EvaluationType} from "../../shared/model/EvaluationType";
import {ProviderType} from "../../shared/model/ProviderType";
import {ActivityType} from "../../shared/model/ActivityType";
import {County} from "../../shared/model/County";
import {SurveyType} from "../../shared/model/SurveyType";
import {Status} from "../../shared/model/Status";
import {evalApi, fundingApi, globalConfig, surveyApi} from "../../plugins/axios";
import moment from "moment";
import {FiscalYear} from "../../shared/model/FiscalYear";
import {Department} from "../../shared/model/Department";
import {Flag} from "@/shared/model/Flag";
import {FundingType} from "@/shared/model/FundingType";
import {rules} from "@/shared/model/Rules";

@Component({
    components: {},
})
export default class FundingTypeForm extends Vue {
    title = "FundingType Form"
    maskText = "";
    valid = true;
    fundingType: FundingType = new FundingType();
    menu1 = false;
    evaluationTypes = EvaluationType.values;
    providerTypes = ProviderType.values;
    activityTypes = ActivityType.values;
    counties = County.values;
    surveyTypes: Array<String> = [];
    statuses = Status.values;
    stateFlags = Flag.values;
    fiscalYears = new FiscalYear().values;
    endDateString: string = "";
    dueDateString: string = "";
    departments = Department.values.sort();
    requiredRule = rules;

    formatDate(date: String): String {
        if (!date) return "MM/DD/YYYY";
        const [year, month, day] = date.split('-')
        return `${month}/${day}/${year}`
    }

    submitNewFundingType(): void {
        evalApi.post("/fundingType/create", {
            fundingTypeId: this.fundingType.fundingTypeId,
            fundingType: this.fundingType.fundingType,
            stateFlag: this.fundingType.stateFlag,
            stateOnlyFlag: this.fundingType.stateOnlyFlag
        }, globalConfig).then(res => {
            //window.location.reload();
            alert(res.data.message + " " + res.data.detail)
        }).catch(error => {
            console.log(`An error occurred submitting the fundingType>>>> ${error}`)
        })
    }
}